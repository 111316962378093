import React, { Suspense } from 'react'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route } from 'react-router-dom';

const LandingPage = React.lazy(() => import('./components/LandingPage/LandingPage'))
const Feed = React.lazy(() => import('./components/Feed/Feed'))
const CreatePost = React.lazy(() => import('./components/CreatePost/CreatePost'))

function App() {
  return (
    <div className="App">
      <Suspense fallback={
        <div className='centered'>Loading...</div>
      }>
        <Route exact path="/" component={LandingPage} />
        <Route path="/feed" component={Feed} />
        <Route exact path="/createpost" component={CreatePost} />
      </Suspense>
    </div>
  );
}

export default App;
