import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    title: '',
    username: '',
    content: '',
    allPosts: []
}

const mainSlice = createSlice({
    name: 'mainslice',
    initialState: initialState,
    reducers: {
        setTitle(state, action) {
            state.title = action.payload
        },
        setUserName(state, action) {
            state.username = action.payload
        },
        setContent(state, action) {
            state.content = action.payload
        },
        setAllPost(state, action) {
            state.allPosts = action.payload
        }
    }
});

export const mainSliceActions = mainSlice.actions;
export const mainSliceReducers = mainSlice.reducer